@import '../../styles/sass/mixins';
.designProcess {
  &__markdown{
    width: 100%;
    display: flex;
    flex-direction: column;    
      p{                
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          flex:2;
          margin-bottom: 2rem;        
          img {
            margin:0 5rem;      
          }  

          @include respond(tab-land) {
            flex-direction: column;
            img:first-child {
              margin-bottom: 10rem;
            }
        }
      }
  
      h3{
        @include respond(tab-port) {
          display: none;
      }
          position: sticky;
          top: 40rem;
          padding-left: 3rem;
          writing-mode: vertical-rl;
          text-orientation: sideways;
          color: #ffffff;
          letter-spacing: 1.2rem;
          margin: 0;
          padding: 0;
          font-weight: 100;
          align-self: flex-start;
          text-transform: uppercase;
      }
      ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        flex-direction: column;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 3rem;
        padding: 0rem 30rem;  
        @include respond(tab-port) {
          padding:0;
          justify-content: center;
          align-items: center;
      }        
        color: #ffffff;
        }
        ol li:first-child {
          margin-top: 0;
        }
        ol li:last-child {
          margin-bottom: 0;
        }
        ol li {
          counter-increment: my-awesome-counter;
          display: flex;
          flex-direction: row;          
          font-size: 1.4rem;
          line-height: 3.2rem;
          margin-bottom: 10rem;    
          @include respond(tab-port) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            width: 60%;
        }  
        }
        ol li::before {
          content: counter(my-awesome-counter);
          font-weight: bold;
          font-size: 11rem;
          margin-right: 7.5rem;
          line-height: 11rem;
          color: #ffffff;
          flex:1;
          @include respond(tab-port) {
            margin: 0 0 2rem 0;            
        }  
        }              
  }

  &__table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @include respond(tab-land) {
      flex-wrap: wrap;
    }
  }

  &__tableCell {    
    white-space: nowrap;  
    @include respond(tab-port) {
      margin: 2rem 0;
    }
  }

  &__tableRow {
    margin: 0 2rem;
    min-width: 30%;
    max-width: 65%;
    @include respond(tab-land) {
      margin: 2rem 0.5rem;
    }
  }
  &__tableRow:first-child{
    @include respond(tab-land){
      margin: 2rem 0.5rem;
    }
    margin: 0 2rem 0 0;
  }

  &__tableCellHeader {
    font-weight:bold;
    text-transform: uppercase;
  }

  &__listItem{
    display: flex;
    flex:11;
    flex-direction: column;

    p{
      align-self: flex-start;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    td,th{
      text-transform: uppercase;      
      white-space: nowrap;      
    }
    td:not(:first-child), th:not(:first-child) {
      padding-left: 14rem;
    }
  }
}