@import '../../styles/sass/mixins';
.modal{
    &__close {
        font-size: 2.9rem;
        margin-right: 13rem;
        cursor: pointer;
        color: white;        
        right: 0;
        position: absolute;
        z-index: 1000;

        @include respond(phone) {
            margin-right: 2rem;
        }
    }
}