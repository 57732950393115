@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");
@import "./_mixins.scss";

$main-background: #131313;

*,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Space Mono", "Roboto", sans-serif;
}

*:focus {
  outline: none;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }
}

html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  background: $main-background;
  @include respond(tab-port) {
    padding: 0;
  }
}

h3{
  font-size:1.17rem;
}
