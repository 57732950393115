@import '../../styles/sass/mixins';
.aboutMe {
  &__markdown {
    width: 100%;
    display: flex;
    font-size: 1.4rem;
    @include respond(tab-port) {    
    align-items: center;
    justify-content: center;
    }

    h3{
      @include respond(tab-port) {
        display: none;
    }
      position: sticky;
      top: 40rem;
      padding-left: 3rem;
      writing-mode: vertical-rl;
      text-orientation: sideways;
      color: #ffffff;
      letter-spacing: 1.2rem;
      margin: 0;
      padding: 0;
      font-weight: 100;
      align-self: flex-start;
      text-transform: uppercase;
    }   
  }

  &__content {
    display: flex;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    width: 83%;
    padding-left: 43rem;
    line-height: 3.2rem;

    @include respond(tab-land) {
      width: 100%;
      padding-left: 23rem;
    }

    p{
      flex: 1 8;
      margin-right: 12rem;
      margin-top: -1.7rem;
    }

    @include respond(tab-port) {      
      flex-direction: column;   
      padding: 0;
      width: 83%;
      p{
        margin-right: 0;
        font-size: 2rem;
        line-height: 1.6;
      }
    }

    blockquote{
      flex: 2;
      height: 100%;
      width: 60%;

      @include respond(tab-port) {
        width: 100%;
      }
      p{
        margin:8rem 0;
        position: relative;
      }
      blockquote {        
        quotes: "“" "”";
        font-style: normal;
        margin: 4rem 0;
        height: auto;
        ::before {
          font-style: normal;
          content: open-quote;
          font-size: 11rem;
          position: absolute;
          top:-2rem;
          left:-1.5rem;

          @include respond(tab-port) {
            top: -8rem;
          }
        }
      }
      blockquote:first-child {
        p{
          margin: 0;
        }
      }
    }

    img {
      margin: 4rem 0;
      display: block;
    }
  }   
}