@import '../../styles/sass/mixins';
.portfolioDetails{
    color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: row;
    @include respond(tab-port){
        align-items: center;
    flex-direction: column;
    } 

    &__container {
        width: 100%;
        height: calc(100vh - 5rem);
        @include respond(tab-port) {
            width: auto;
        }
    }

    h3{
        @include respond(tab-port) {
            display: none;
        }
        position: sticky;
        top: 37rem;
        padding-left: 3rem;
        color: #ffffff;
        writing-mode: vertical-rl;
        text-orientation: sideways;
        letter-spacing: 1.2rem;
        margin: 0;
        padding: 0;
        font-weight: 100;
        align-self: flex-start;
        text-transform: uppercase;
    }
    h2 {
        font-size: 4rem;
        text-transform: lowercase;
        letter-spacing: 2px;
    }
}


.custom {
    width: 100%;
    height: 100%;
}

.sliderElement{
    margin: 0 22rem;
    @include respond(tab-port) {
        margin: 0;
        width: 40rem;
        min-height: 70vh;
    }
    display: flex;
    flex-direction: column;
}

.carouselContainer {
    height: 95vh;
    overflow-y: auto;
}

.navigationMobile {
    display: none;
}

.navigationIconLeft {
    font-size: 5rem;
    margin-right: 10rem;
    @include respond(tab-port){
        position: absolute;
        bottom: -6rem;
        left: -5rem;
    }
    @include respond(phone) {
        display: none;
    }
}
.navigationIconRight {
    font-size: 5rem;
    margin-right: -5rem;
    @include respond(tab-port){
        position: absolute;
        bottom: -6rem;
        left: 7rem;
    }
    @include respond(phone) {
        display: none;
    }
}
.imageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include respond(tab-port){
        justify-content: center;
    }
    
    img{        
        margin: 1rem;
    }
}

.descriptionContainer {
    max-width: 60%;
    line-height: 4rem;
    margin: 3rem 1rem;
    text-align: left;
    font-size: 1.4rem;
    @include respond(tab-port){
        max-width: none;
        margin: 0;
        line-height: 2.8rem;      
    }
}