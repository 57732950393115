@import '../../styles/sass/mixins';
.footer {
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;    
    bottom: 0;    
    height: 5rem;
    background-color: #131313;

    &__impressum {
    flex: 2 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    p{        
        font-size: 1.2rem;
        margin: 0 6.5rem;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        span {
            font-size: 1.2rem;
            font-family: sans-serif;
        }
    }
    p:first-child {
        margin: 0 7.5rem;
    }
    }

    &__ajanlat {
        -webkit-animation:backandforth 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;
        animation: backandforth 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;
        a{
            color: #ffffff;
            display: block;
            width: 15rem;
        }
        a:hover, a:visited{
            color: #ffffff;
        }
        font-size: 3rem;
        text-transform: uppercase;        
        position: fixed;
        right: 1rem;

        @include respond(tab-port) {
            right:2rem;
            -webkit-animation:upanddown 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;
            animation: upanddown 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;
            a{
                width: auto;
                text-decoration: none;
            }        
        }
        bottom: 1.5rem;
        
        p{
            @include respond(tab-port) {
                writing-mode: vertical-rl;
                text-orientation: sideways;
                margin: 0;
                letter-spacing: 2.6rem;                
                font-size: 1.4rem;
                margin: 0;
                padding: 0;
                font-weight: 100;
            }
        }

        
text {
    fill: #ffffff;    
    font-size: 0.8rem;
  }
  path, circle {
    fill: transparent;
  }
    }
}

 @keyframes backandforth {
     0%{}
     5%{
        transform: rotate(0);
     }
     10%{
         transform: rotate(-90deg);
     }
     15% {
        transform: rotate(90deg);
     }
     20%{
         transform: rotate(0);
     }
     100% {
        transform: rotate(0);
     }
 }

 @keyframes upanddown {
    0%{}
    5%{
       transform: translateY(0);
    }
    10%{
        transform: translateY(-70px);
    }
    15% {
       transform: translateY(30px);
    }
    20%{
        transform: translateY(0);
    }
    100% {
       transform: translateY(0);
    }
}
  