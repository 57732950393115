@import '../../styles/sass/mixins';
.contact {
  &__markdown {
    width: 100%;
    display: flex;
    font-size: 1.4rem;

    h3{
      @include respond(tab-port) {
        display: none;
    }
      position: sticky;
      top: 40rem;
      padding-left: 3rem;
      writing-mode: vertical-rl;
      text-orientation: sideways;
      color: #ffffff;
      letter-spacing: 1.2rem;
      margin: 0;
      padding: 0;
      font-weight: 100;
      align-self: flex-start;
      text-transform: uppercase;
    }   
  }

  &__content {
    display: flex;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    width: 100%;    
    line-height: 3.2rem;

    @include respond(tab-port) {
      padding: 0;
      p{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        a{
          margin: 1rem 0;
        }
      }
    }

    img {
      margin: 0 3rem;
    }

    p:not(:first-child){
      margin-left: 15rem;
    }
  }   
}