@import '../../styles/sass/mixins';
.header {
    &__container {        
        display: flex;
        flex-direction: row;
        align-items: center;    
        height: 5rem;         
    }
    &__logoDesktop {
        @include respond(tab-port) {
            display: none;
        }
    }

    // kockák: 1rem, -1.8, 1.9
    &__logoMobile {
        display: none;
        @include respond(tab-port) {
            display: block;
        }
    }
    &__logo{
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 1rem;
        img{            
            margin-top: 0.5rem;            
            @include respond(tab-port) {                
                position: fixed;
                top: 2rem;
                left: 4rem;
                margin:0;

            }
            
        @include respond(phone) {
            top: 3rem;
            left: 2rem;
        }
        }
    }
    &__menu {        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;      
        ul{
            padding:0;
        }  
    }
}
.navigation {
    &__checkbox {
        display: none;
    }

    &__button {
        height: 7rem;
        width: 7rem;
        position: fixed;
        top: 6rem;
        right: 6rem;
        z-index: 2000;
        text-align: center;
        cursor: pointer;

        @include respond(tab-port) {
            top: 2rem;
            right: 4rem;
        }

        @include respond(phone) {
            top: 3rem;
            right: 2rem;
        }
    }

    &__background {
        height: 6rem;
        width: 6rem;        
        position: fixed;
        top: 6.5rem;
        right: 6.5rem;        
        z-index: 1000;
        transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
        background-color: #131313;        

        @include respond(tab-port) {
            top: 4.5rem;
            right: 4.5rem;
        }

        @include respond(phone) {
            top: 3.5rem;
            right: 2.5rem;
        }
    }

    &__list {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style: none;
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        margin: 1rem;
    }

    &__navHorizontal {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;

            li {
                float: left;
                cursor: pointer;
            }
        }
    }

    &__nav {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -2;

        opacity: 0;
        width: 0;
        transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    //FUNCTIONALITY
    &__checkbox:checked ~ &__background {
        transform: scale(80);
    }

    &__checkbox:checked ~ &__nav {
        opacity: 1;
        width: 100%;
        z-index: 1500;
    }

    //ICON
    &__icon1, &__icon2, &__icon3 {
        position: relative;
        margin-top: 3.1rem;        

        &,
        &::before,
        &::after {
            width: 1rem;
            height: 1rem;   
            background-color: #ffffff;
            display: inline-block;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            transition: all 0.2s;
        }

        &::before {
            top: -18px;
        }

        &::after {
            top: 18px;
        }
    }
    &__icon1{
        left:-10px;
    }

    &__icon2 {
        left: 0;
    }
    &__icon3 {
        left: 10px;
    }

    &__checkbox:checked + &__button &__icon1, &__checkbox:checked + &__button &__icon2,&__checkbox:checked + &__button &__icon3 {
        background-color: transparent;
    }

    &__checkbox:checked + &__button &__icon1::before, &__checkbox:checked + &__button &__icon2::before ,&__checkbox:checked + &__button &__icon3::before  {
        top: 0;
    }

    &__checkbox:checked + &__button &__icon1::after,  &__checkbox:checked + &__button &__icon2::after, &__checkbox:checked + &__button &__icon3::after {
        top: 0;
    }

    &__nav,
    &__navHorizontal {
        a {
            position: relative;
            text-decoration: none;
            @include underline(0.6rem);
        }
    }

    &__link {
        &:link,
        &:visited {
            display: inline-block;
            font-size: 3rem;
            font-weight: 300;
            padding: 3rem 0;
            color: #ffffff;
            text-decoration: none;
            background-size: 220%;
            transition: all 0.4s;

            span {
                @include respond(tab-port) {
                    margin-right: 0;
                }

                margin-right: 1.5rem;
                display: inline-block;
            }
        }
    }

    &__item {
        font-size: 1.4rem;
        color: #ffffff;
        text-transform: uppercase;
        display: inline-block;
        margin: 0 10rem;
        cursor: pointer;
        @include respond(tab-port) {
            margin: 0;
        }        
    }

    &Mobile {
        display: none;

        @include respond(tab-port) {
            display: block;
        }
    }

    &Desktop {        
        @include respond(tab-port) {
            display: none;
        }
    }
}
