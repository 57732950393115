@mixin mmp-box-shadow($color) {
  box-shadow: 2px 2px 6px 2px $color;
}

@mixin respond($breakpoint) {
  @if $breakpoint==mini-phone {
    @media only screen and (max-width: 17.5em) {
      @content;
    } //280px
  }

  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }

  @if $breakpoint==tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }

  @if $breakpoint==tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }

  @if $breakpoint==big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}

@mixin paddingOnTabPort {
  @include respond(tab-port) {
      padding: 10rem 2rem;
  }

  @include respond(tab-land) {
      padding: 10rem 2rem;
  }
}


%heading__image {
  position: absolute;
  overflow: hidden;
  transform: translateX(-50%);
  left: 50%;
  z-index: -1;
  max-width: 158.3rem;
}

@mixin underline($howFar) {
  &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1 * $howFar;
      left: 0;      
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
  }

  &:hover::before {
      visibility: visible;
      transform: scaleX(1);
  }
}
