@import '../../styles/sass/mixins';
.layout {
  &__wrapper {
    padding: 0 3rem;
    @include respond(tab-port) {
      padding: 0;
  }
  }
  
  &__design, 
  &__portfolioDetails,  
  &__aboutMe{
    margin: 5rem 0;
  }

  &__contact {
    margin: 0 0 5rem 0;
  }
  
  &__aboutMe {
    margin-top:8rem;
  }

  &__header{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 5rem;
    @include respond(tab-port) {
      height: 12rem;
  }
    background-color: #131313;
    z-index: 2;
  }

  &__anchor{    
      display: block;
      height: 5rem; /*same height as header*/
      margin-top: -5rem; /*same height as header*/
      visibility: hidden;
    }
}
