@import '../../styles/sass/mixins';
.portfolio {
    &__markdown{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        p{                
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex:2;
            margin-top: 10rem;
            img{                
                margin-bottom: 25rem;
                scroll-snap-align: center;
                cursor: pointer;
                transition: all .2s ease-in-out;
            }
            img:hover {
                transform: scale(1.2);
            }
        }
    
        h3{
            @include respond(tab-port) {
                display: none;
            }
            position: sticky;
            top: 40rem;
            padding-left: 3rem;
            writing-mode: vertical-rl;
            text-orientation: sideways;
            color: #ffffff;
            letter-spacing: 1.2rem;
            margin: 0;
            padding: 0;
            font-weight: 100;
            align-self: flex-start;
            text-transform: uppercase;
        }
    }

    &__arrow {
        min-height: 30rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 30rem;                    
        font-size: 5rem;
        color: #FFFFFF;
        justify-content: center;
        svg{
            cursor:pointer;
        }
        span{
            font-size: 1.4rem;
            text-transform: uppercase;
        }
    }
}